<template>
  <div id="offer-commission">
    <validation-observer ref="offer-commission-observer" #default="{ invalid, validate, pristine, valid, dirty }">
      <b-form ref="offer-commission-form" @submit.prevent="validate().then(() => submitOfferCommission(clickSaveAndNext))">
<!--      <b-form ref="offer-commission-form" @submit.prevent="validate().then(submitOfferCommission)">-->
        <div class="m-2">
          <h3 class="mb-2">
            {{ $tc('offer.commission.title', 0) }}
          </h3>
          <b-row>
            <b-col cols="12" lg="4">
              <!-- commissionSliceType -->
              <enum-select
                v-if="offerEdition"
                id="offer-commission-slice-type"
                v-model="offerCommission.edited.offer.commissionSliceType"
                :label="$t('offer.commission.slice_type')"
                enum-class="CommissionSliceType"
                :disabled="offerCommissionLoading"
                :overlay="offerCommissionLoading"
                :clearable="false"
                required
                @option:selected="submitOfferCommission()"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.commission.slice_type') }} : </span>
                <span>
                  {{ offerCommission.edited.offer.commissionSliceType }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- commissionType -->
              <enum-select
                v-if="offerEdition"
                id="offer-commission-type"
                v-model="offerCommission.edited.offer.commissionType"
                :label="$t('offer.commission.type')"
                enum-class="CommissionType"
                :overlay="offerCommissionLoading"
                :disabled="offerCommissionLoading"
                :clearable="false"
                required
                @option:selected="submitOfferCommission()"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.commission.type') }} : </span>
                <span>
                  {{ offerCommission.edited.offer.commissionType }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- minCommission -->
              <app-input
                v-if="offerEdition"
                id="offer-commission-min-commission"
                v-model.number="offerCommission.edited.offer.minCommission"
                :label="$t('offer.commission.min_commission')"
                :overlay="offerCommissionLoading"
                :disabled="offerCommissionLoading"
                :append="getCurrency()"
                type="number"
                rules="required|decimalOrInt"
                @change="submitOfferCommission(false)"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.commission.min_commission') }} : </span>
                <span>
                  {{ offerCommission.edited.offer.minCommission }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- defaultCommissionRate -->
              <app-input
                v-if="offerEdition"
                id="offer-commission-default-commission-rate"
                v-model.number="offerCommission.edited.offer.defaultCommissionRate"
                :label="$t('offer.commission.default_commission_rate')"
                :disabled="offerCommissionLoading"
                :overlay="offerCommissionLoading"
                rules="required|decimalOrInt"
                append="%"
                type="number"
                @change="submitOfferCommission()"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.commission.default_commission_rate') }} : </span>
                <span>
                  {{ offerCommission.edited.offer.defaultCommissionRate }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- defaultFixedCommission -->
              <app-input
                v-if="offerEdition"
                id="offer-commission-default-fixed-commission"
                v-model.number="offerCommission.edited.offer.defaultFixedCommission"
                :label="$t('offer.commission.default_fixed_commission')"
                :disabled="offerCommissionLoading"
                :overlay="offerCommissionLoading"
                :append="getCurrency()"
                type="number"
                rules="required|decimalOrInt"
                @change="submitOfferCommission()"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.commission.default_fixed_commission') }} : </span>
                <span>
                  {{ offerCommission.edited.offer.defaultFixedCommission }}
                </span>
              </span>
            </b-col>
          </b-row>
        </div>

        <!-- commissions -->
        <OfferPartCommissionCategories
          v-if="!offerCommissionLoading && offerCommission.edited.offer.commissionType === 'commission_per_categories'"
          :commissions="offerCommission.edited.commissions.length ? offerCommission.edited.commissions : []"
          :offer-commission-loading="offerCommissionLoading"
          :offer-edition="offerEdition"
          :offer-id="offerId"
          @categoriesGroupUpdated="fetchOfferCommission(true)"
          @slicesUpdated="fetchOfferCommission(true)"
        />
        <OfferPartCommissionSlices
          v-if="!offerCommissionLoading && offerCommission.edited.offer.commissionType === 'commission_global'"
          :offer-commission-id="offerCommission.edited.commissions[0].id"
          :offer-commission-loading="offerCommissionLoading"
          :offer-edition="offerEdition"
          :offer-id="offerId"
          :slices="offerCommission.edited.commissions.length ? offerCommission.edited.commissions[0].slices : []"
          @slicesUpdated="fetchOfferCommission(true)"
        />

        <!-- submit and reset -->
        <div class="my-2">
          <app-form-multiple-actions
            :disabled="invalid || pristine || offerCommissionLoading"
            :next="pristine || invalid"
            :save="offerEdition"
            :save-next="valid && dirty && offerEdition"
            previous
            @click:previous="$router.push(`/offer/${offerId}/typologies/${offerEditionMode}`)"
            @click:save="isClickSave()"
            @click:save-next="isClickSaveAndNext()"
            @click:next="$router.push(`/offer/${offerId}/subscription/${offerEditionMode}`)"
          />
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppFormMultipleActions from '@/components/AppFormMultipleActions.vue'
import getCurrency from '@/helpers/getCurrency'
import { fetchOfferCommissionsRequest, patchOfferCommissionRequest } from '@/request/globalApi/requests/offerRequests'

export default {
  name: 'OfferPartCommission',

  components: {
    OfferPartCommissionSlices: () => import('./OfferPartCommissionSlices.vue'),
    OfferPartCommissionCategories: () => import('./OfferPartCommissionCategories.vue'),
    AppFormMultipleActions,
  },

  props: {
    offerEdition: {
      type: Boolean,
      required: true,
    },
    offerEditionMode: {
      type: String,
      default: 'show',
    },
    offerId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      offerCommissionLoading: false,
      offerCommission: {
        old: {},
        // TODO remove
        edited: {
          offer: {
            additionalCommission: true,
            commissionSliceType: '',
            commissionType: '',
            minCommission: null,
            defaultCommissionRate: null,
            defaultFixedCommission: null,
          },
          commissions: [{ id: null, categories: [], slices: [] }],
        },
      },
      clickSaveAndNext: true
    }
  },

  computed: {
    ...mapState('appConfig', ['lang']),

    ...mapGetters('offerCommission', ['offerCommissionById']),
    ...mapGetters('offerInformation', ['offerReferenceById']),
  },

  mounted() {
    this.fetchOfferCommission()
  },

  methods: {
    ...mapActions('offerCommission', ['setOfferCommissionsShowed', 'updateOfferCommissionsShowed']),
    getCurrency,

    resetOfferCommission() {
      this.offerCommission.edited = this._cloneDeep(this.offerCommission.old)
      this.$refs['offer-commission-observer'].reset()
    },

    clearOfferCommission() {
      this.offerCommission.edited = {}
      this.offerCommission.old = {}
      this.$refs['offer-commission-observer'].reset()
    },

    saveOfferCommission(offerCommission) {
      this.offerCommission.edited = this._cloneDeep(offerCommission)
      this.offerCommission.old = this._cloneDeep(this.offerCommission.edited)
    },

    submitOfferCommission(next = false) {
      this.offerCommissionLoading = true
      patchOfferCommissionRequest(this.offerId, this.offerCommission.edited.offer, this.offerReferenceById({ id: this.offerId, lang: this.lang }))
        .then(() => {
          this.fetchOfferCommission(true)
          this.$emit('offerEdited')
        })
        .then(() => {
          next && this.$router.push(`/offer/${this.offerId}/subscription/${this.offerEditionMode}`)
        })
        .catch(err => {
          err && (this.offerCommissionLoading = false)
        })
    },

    fetchOfferCommission(refresh) {
      let commission = this.offerCommissionById(this.offerId)
      if (commission && !refresh) {
        this.saveOfferCommission(commission)
      } else {
        this.offerCommissionLoading = true
        fetchOfferCommissionsRequest(this.offerId)
          .then(response => {
            commission = response.data
            this.saveOfferCommission(commission)
            !refresh
              && this.setOfferCommissionsShowed({
                id: this.offerId,
                commission,
              })
            refresh
              && this.updateOfferCommissionsShowed({
                id: this.offerId,
                commission,
              })
          })
          .finally(() => {
            this.offerCommissionLoading = false
          })
      }
    },

    isClickSave() {
      this.clickSaveAndNext = false
    },

    isClickSaveAndNext() {
      this.clickSaveAndNext = true
    }
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  min-width: 200px;
}
</style>
