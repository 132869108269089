<template>
  <div id="offer-informations">
    <validation-observer ref="offer-informations-observer" #default="{ invalid, validate, pristine, valid, dirty, untouched, touched }">
      <b-form ref="offer-informations-form" @submit.prevent="validate().then(() => submitOffer(clickSaveAndNext))">
        <div class="m-2">
          <div class="d-flex">
            <h3 class="mb-2">
              {{ $tc('offer.information.title', 0) }}
            </h3>
          </div>
          <b-row :class="{ 'mb-2': !offerEdition }">
            <b-col cols="12" md="8">
              <!-- reference-->
              <app-form-text-input-itn
                v-if="offerEdition"
                v-model="offer.edited.reference"
                :default-lang="$store.state.auth.defaultLang"
                :label="$t('offer.reference')"
                :languages="$store.state.auth.activelang"
                :loading="offerInformationsLoading"
                form-name="offer-informations"
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.reference') }} : </span>
                <span v-if="offer.edited.reference">
                  {{ offer.edited.reference | trans }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" md="4">
              <!-- offerActive -->
              <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('common.active')" rules="required">
                <b-form-group :label="$t('common.active')" class="mt-md-1 validation-required" label-for="offer-active">
                  <b-overlay :show="offerInformationsLoading">
                    <v-select
                      v-model="offer.edited.active"
                      :clearable="false"
                      :options="[
                        { text: 'Active', value: true },
                        { text: 'Inactive', value: false },
                      ]"
                      :placeholder="$t('common.active')"
                      :reduce="name => name.value"
                      class="select-size-md"
                      input-id="offer-active"
                      label="value"
                    >
                      <template #option="{ text, value }">
                        <b-badge v-if="value" pill variant="light-success"> {{ text }} </b-badge>
                        <b-badge v-else pill variant="light-danger"> {{ text }} </b-badge>
                      </template>
                      <template #selected-option="{ text, value }">
                        <b-badge v-if="value" pill variant="light-success"> {{ text }} </b-badge>
                        <b-badge v-else pill variant="light-danger"> {{ text }} </b-badge>
                      </template>
                      <template #no-options> {{ $t('common.no_option_found') }} </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-overlay>
                </b-form-group>
              </validation-provider>
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('common.active') }} : </span>
                <span>
                  <b-badge v-if="offer.edited.active" pill variant="light-success"> Active </b-badge>
                  <b-badge v-else pill variant="light-danger"> Inactive </b-badge>
                </span>
              </span>
            </b-col>
          </b-row>
          <b-row :class="{ 'mb-2': !offerEdition }">
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="12" lg="6">
                  <!--Start at-->
                  <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('common.start_at')" rules="">
                    <b-form-group :label="$t('common.start_at')" label-for="start-at">
                      <b-overlay :show="offerInformationsLoading">
                        <b-form-datepicker
                          id="start-at"
                          v-model="offer.edited.startAt"
                          :locale="$i18n.locale"
                          :max="offer.edited.endAt"
                          :placeholder="$t('common.start_at')"
                          :state="errors[0] ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-overlay>
                    </b-form-group>
                  </validation-provider>
                  <span v-else class="text-nowrap">
                    <span class="font-weight-bold"> {{ $t('common.start_at') }} : </span>
                    <span>
                      {{
                        offer.edited.startAt
                          ? new Date(offer.edited.startAt).toLocaleDateString($i18n.locale, {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            weekday: 'short',
                          })
                          : '/'
                      }}
                    </span>
                  </span>
                </b-col>
                <b-col cols="12" lg="6">
                  <!--End at-->
                  <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('common.end_at')" rules="">
                    <b-form-group :label="$t('common.end_at')" label-for="end-at">
                      <b-overlay :show="offerInformationsLoading">
                        <b-form-datepicker
                          id="end-at"
                          v-model="offer.edited.validityDate"
                          :locale="$i18n.locale"
                          :min="offer.edited.startAt"
                          :placeholder="$t('common.end_at')"
                          :state="errors[0] ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-overlay>
                    </b-form-group>
                  </validation-provider>
                  <span v-else class="text-nowrap">
                    <span class="font-weight-bold"> {{ $t('common.end_at') }} : </span>
                    <span>
                      {{
                        offer.edited.validityDate
                          ? new Date(offer.edited.validityDate).toLocaleDateString($i18n.locale, {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            weekday: 'short',
                          })
                          : '/'
                      }}
                    </span>
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-lg-1">
                <div class="d-none d-lg-block">
                  <b-col cols="12">
                    <span v-if="offerEdition" class="mb-2">{{ $t('common.note') }} {{ $t('offer.date_note') }}</span>
                  </b-col>
                </div>
              </b-row>
            </b-col>
            <b-col cols="12" lg="4">
              <b-row align-v="center" class="mb-lg-m1">
                <b-col cols="12" lg="12" md="6">
                  <!-- duration -->
                  <enum-select
                    v-if="offerEdition"
                    id="offer-duration"
                    v-model="offer.edited.duration"
                    :label="$t('offer.duration')"
                    enum-class="OfferDuration"
                    :overlay="offerInformationsLoading"
                    :clearable="false"
                    required
                  />
                  <span v-else class="text-nowrap">
                    <span class="font-weight-bold"> {{ $t('offer.duration') }} : </span>
                    <span>{{ offer.edited.duration }}</span>
                  </span>
                </b-col>

                <b-col cols="12" lg="12" md="6">
                  <!-- reconductible -->
                  <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('offer.reconductible')" rules="">
                    <b-form-group>
                      <b-form-checkbox
                        v-if="offerEdition"
                        id="offer-reconductible"
                        v-model="offer.edited.reconductible"
                        :disabled="offerInformationsLoading"
                        name="offer-reconductible"
                      >
                        {{ $t('offer.reconductible_text') }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <span v-else class="text-nowrap">
                    <span class="font-weight-bold"> {{ $t('offer.reconductible_text') }} : </span>
                    <span>
                      {{ offer.edited.reconductible ? $t('common.yes') : $t('common.no') }}
                    </span>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row :class="{ 'mb-2': !offerEdition }">
            <b-col cols="12" lg="4">
              <!-- main-typology -->
              <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('offer.main_typology')" rules="required">
                <b-form-group :label="$t('offer.main_typology')" class="validation-required" label-for="offer-main-typology">
                  <b-overlay :show="offerInformationsLoading || organizationTypologiesLoading">
                    <v-select
                      v-model="offer.edited.mainTypology.id"
                      :clearable="false"
                      :get-option-label="opt => opt.name[$i18n.locale]"
                      :options="organizationTypologies"
                      :placeholder="$t('offer.main_typology')"
                      :reduce="name => name.id"
                      class="select-size-md"
                      input-id="offer-main-typology"
                      @input="mainTypologiesChanged = true"
                    >
                      <template #no-options> {{ $t('common.no_option_found') }} </template>
                    </v-select>
                  </b-overlay>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.main_typology') }} : </span>
                <span v-if="offer.edited.mainTypology && offer.edited.mainTypology.name">
                  {{ offer.edited.mainTypology.name | trans }}
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4" md="6">
              <!-- offerType -->
              <enum-select
                v-if="offerEdition"
                id="offer-type"
                v-model="offer.edited.type"
                :label="$t('common.type')"
                class="select-size-md"
                enum-class="OfferType"
                :overlay="offerInformationsLoading"
                :clearable="false"
                required
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('common.type') }} : </span>
                <span>
                  <b-badge v-if="offer.edited.type === 'standard'" pill variant="light-success">
                    {{ offer.edited.type }}
                  </b-badge>
                  <b-badge v-if="offer.edited.type === 'custom'" pill variant="light-info">
                    {{ offer.edited.type }}
                  </b-badge>
                </span>
              </span>
            </b-col>
            <b-col cols="12" lg="4" md="6">
              <!-- maxSubscription -->
              <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('offer.max_subscription')" rules="numeric">
                <b-form-group :label="$t('offer.max_subscription')" label-for="offer-max-subscription">
                  <b-overlay :show="offerInformationsLoading">
                    <b-form-input
                      id="offer-max-subscription"
                      v-model="offer.edited.maxSubscription"
                      :placeholder="$t('offer.max_subscription')"
                      type="number"
                    />
                  </b-overlay>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.max_subscription') }} : </span>
                <span>
                  {{ offer.edited.maxSubscription ? offer.edited.maxSubscription : '/' }}
                </span>
              </span>
            </b-col>
          </b-row>
          <b-row :class="{ 'mb-2': !offerEdition }">
            <b-col cols="12" lg="8">
              <!-- internalDescription -->
              <validation-provider #default="{ errors }" :name="$t('offer.internal_description')" rules="">
                <b-form-group :label="offerEdition ? $t('offer.internal_description') : ''" label-for="offer-internal-description">
                  <b-overlay :show="offerInformationsLoading">
                    <span v-if="!offerEdition" class="text-nowrap font-weight-bold"> {{ $t('offer.internal_description') }} : </span>
                    <b-form-textarea
                      id="offer-internal-description"
                      v-model="offer.edited.internalDescription"
                      :placeholder="$t('offer.internal_description')"
                      :plaintext="offerEdition ? false : true"
                      :rows="offerEdition ? 8 : 0"
                      class="hide-scroll"
                      max-rows="8"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-overlay>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- icon -->
              <b-form-group v-if="offerEdition" :label="$t('offer.icon')" label-for="offer-icon">
                <b-overlay :show="offerInformationsLoading || dropZoneLoading" spinner-medium>
                  <app-drop-zone
                    id="offer-icon"
                    ref="offer-icon"
                    :value="{
                      name: offer.edited.iconFilePath,
                      fileUrl: offer.edited.iconFileUrl,
                    }"
                    :options="dropzoneOptions"
                    class="dropzone"
                    @vdropzone-removed-file="removeFile(offer.edited.iconFileUrl, offer.edited.iconFilePath, offer.edited, 'deleteOldIconFile')"
                    @vdropzone-file-added="sendingFile($event, offer.edited, 'iconFileId', 'iconUploading', 'deleteOldIconFile', 'iconFileUrl', 'offer-icon')"
                  />
                </b-overlay>
              </b-form-group>
              <div v-else class="ml-1">
                <b-row>
                  <span class="text-nowrap font-weight-bold"> {{ $t('offer.icon') }} : </span>
                </b-row>
                <b-row class="mt-1">
                  <b-img
                    v-if="offer.edited.iconFileUrl"
                    :alt="`${$t('common.no')} ${$t('offer.icon')}`"
                    :src="offer.edited.iconFileUrl"
                    class="img-container"
                    fluid
                    rounded
                  ></b-img>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <!-- description -->
              <div v-if="offerEdition && offer.edited.description" class="description hide-scroll">
                <app-form-text-editor-itn
                  v-model="offer.edited.description"
                  :default-lang="$store.state.auth.defaultLang"
                  :disabled="offerInformationsLoading"
                  :label="$t('common.description')"
                  :languages="$store.state.auth.activelang"
                  form-name="offer-informations"
                />
              </div>

              <div v-else class="mb-2">
                <span class="text-nowrap font-weight-bold"> {{ $t('common.description') }} : </span>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="description hide-scroll mt-1" v-html="$options.filters.trans(offer.edited.description)" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-if="$can('OPERATOR_SUBSCRIPTION_DELETE')"
                id="offer-delete"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                :block="$store.getters['app/mdAndDown']"
                :disabled="offerInformationsLoading"
                class="btn-icon"
                variant="danger"
                @click="subscribersNumber === 0 ? softDeleteOffer(offer.edited) : toggleSoftDelete(offerId)"
              >
                <span class="text-nowrap">
                  <feather-icon class="mr-25" icon="TrashIcon" />
                  {{ $t('offer.delete') }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div class="my-2">
          <app-form-multiple-actions
            :disabled="invalid || pristine || offerInformationsLoading"
            :next="pristine || invalid || untouched"
            :save="offerEdition"
            :save-next="valid && dirty && offerEdition && touched"
            @click:save="isClickSave()"
            @click:save-next="isClickSaveAndNext()"
            @click:next="$router.push(`/offer/${offerId}/typologies/${offerEditionMode}`)"
          />
        </div>
      </b-form>
    </validation-observer>
    <OfferDeletionSoft
      :offer="reaffectedOffer"
      :offer-deletion="offerDeletion"
      :offer-deletion-mode="offerDeletionMode"
      :offer-loading="offerInformationsLoading"
      @editionUpdated="offerDeletion = $event"
      @offerUpdated="softDeleteOffer($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import {
  deleteOfferDeleteRequest,
  fetchOfferInfoRequest,
  patchOfferInfoRequest,
} from '@/request/globalApi/requests/offerRequests'

import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

import imgUpload from '@/helpers/imgUpload'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppFormTextEditorItn from '@/components/AppFormTextEditorItn.vue'
import AppDropZone from '@/components/AppDropZone.vue'
import AppFormMultipleActions from '@/components/AppFormMultipleActions.vue'
import OfferDeletionSoft from './OfferDeletionSoft.vue'

export default {
  name: 'OfferPartInformations',

  components: {
    AppFormTextInputItn,
    AppFormTextEditorItn,
    AppDropZone,
    OfferDeletionSoft,
    AppFormMultipleActions,
  },
  mixins: [imgUpload],

  props: {
    offerEdition: {
      type: Boolean,
      required: true,
    },
    offerEditionMode: {
      type: String,
      required: true,
    },
    offerId: {
      type: Number,
      required: true,
    },
    subscribersNumber: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      offerInformationsLoading: false,
      offer: {
        old: {},
        edited: { mainTypology: { id: null }, reference: null },
      },
      offerDeletion: false,
      offerDeletionMode: '',
      reaffectedOffer: {},
      dropZoneLoading: false,
      dropzoneOptions: {
        // TODO set default in component
        autoProcessQueue: false,
        // TODO set default in component
        url: 'https://localhost',
        dictDefaultMessage: this.$t('offer.icon_upload'),
        // TODO set default in component
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFiles: 1,
        // TODO set default in component
        maxFilesize: 2000000,
        acceptedFiles: 'image/png,image/jpeg',
        addRemoveLinks: true,
        clickable: true,
      },
      clickSaveAndNext: true
    }
  },

  computed: {
    ...mapState('appConfig', ['lang']),
    ...mapState('organizationConfiguration', ['organizationTypologies', 'organizationTypologiesLoading']),
    ...mapGetters('offerInformation', ['offerInformationById']),
    ...mapFields('offerInformation', ['mainTypologiesChanged']),
  },

  mounted() {
    this.fetchOrganizationConfigurationTypologies()
    this.fetchOfferInformations(true)
  },

  methods: {
    ...mapActions('organizationConfiguration', ['fetchOrganizationConfigurationTypologies']),
    ...mapActions('offerInformation', ['setOfferInformationsShowed', 'updateOfferInformationsShowed']),

    resetOffer() {
      this.offer.edited = this._cloneDeep(this.offer.old)
      this.$refs['offer-informations-observer'].reset()
    },

    saveOffer(offer) {
      this.offer.edited = this._cloneDeep(offer)
      this.offer.old = this._cloneDeep(this.offer.edited)
    },

    submitOffer(next) {
      this.offerInformationsLoading = true
      // API respond null but need Object empty to patch ??
      !this.offer.edited.description && (this.offer.edited.description = {})
      patchOfferInfoRequest(this.offerId, this.offer.edited, this.offer.edited.reference[this.lang])
        .then(() => {
          this.offer.edited.iconFileId = null
          this.$emit('offerEdited')
        })
        .then(() => {
          next && this.$router.push(`/offer/${this.offerId}/typologies/${this.offerEditionMode}`)
        })
        .finally(() => {
          this.offerInformationsLoading = false
        })
    },

    softDeleteOffer(offer) {
      deleteOfferDeleteRequest(
        offer.id,
        { suggestedOffersIds: offer.suggestedOffersIds, defaultOfferId: offer.defaultOfferId },
        this.offer.edited.reference[this.lang],
      ).then(response => {
        response && this.$router.push({ name: 'offers' })
      })
    },

    toggleSoftDelete(offerId) {
      this.reaffectedOffer = {
        id: offerId,
        suggestedOffersIds: [],
        defaultOfferId: null,
      }
      this.offerDeletionMode = 'delete'
      this.offerDeletion = true
    },

    fetchOfferInformations(refresh) {
      let offer = this.offerInformationById(this.offerId)
      if (offer && !refresh) {
        this.saveOffer(offer)
      } else {
        this.offerInformationsLoading = true
        fetchOfferInfoRequest(this.offerId)
          .then(response => {
            offer = response.data.offer
            this.saveOffer(offer)
            !refresh && this.setOfferInformationsShowed(offer)
            refresh && this.updateOfferInformationsShowed(offer)
          })
          .finally(() => {
            this.offerInformationsLoading = false
          })
      }
    },

    sendOfferIconFile(file) {
      if (!this.offer.termsFileUrl) {
        this.dropZoneLoading = true
        const formData = new FormData()
        formData.append('temporary_file', file)
        postFileRequest(formData)
          .then(response => {
            this.offer.edited.iconFileId = response.data.id
            this.offer.edited.deleteOldIconFile = false
          })
          .finally(() => {
            this.dropZoneLoading = false
          })
      }
    },

    isClickSave() {
      this.clickSaveAndNext = false
    },

    isClickSaveAndNext() {
      this.clickSaveAndNext = true
    }
  },
}
</script>
<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.img-container {
  height: 177px;
}
.dropzone {
  height: 203px;
}
.description {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
