<template>
  <div id="offer-subscription">
    <validation-observer #default="{ invalid, validate, pristine, valid, dirty }" ref="offer-subscription-observer">
      <b-form ref="offer-subscription-form" @submit.prevent="validate().then(() => submitOfferSubscription(clickSaveAndNext))">
        <div class="m-2">
          <h3 class="mb-2">
            {{ $tc('offer.subscription.title', 0) }}
          </h3>
          <!-- PAYMENT -->
          <b-row>
            <b-col cols="12" md="12">
              <!-- paymentFrequency -->
              <enum-select
                v-if="offerEdition"
                id="offer-subscription-payment-frequency"
                v-model="offerSubscription.edited.paymentFrequency"
                :label="$t('offer.subscription.payment_frequency')"
                enum-class="PaymentFrequency"
                :overlay="offerSubscriptionLoading"
                class="select-size-md"
                required
              />
              <span v-else class="text-nowrap">
                <span class="font-weight-bold"> {{ $t('offer.subscription.payment_frequency') }} : </span>
                <span>
                  {{ offerSubscription.edited.paymentFrequency }}
                </span>
              </span>
            </b-col>
          </b-row>
        </div>
        <!-- TABLE -->
        <b-table
          ref="offer-subscription-table"
          class="position-relative table-dropdown-action"
          thead-class="text-nowrap"
          primary-key="name"
          :items="offerSubscription.edited.options"
          :busy="offerSubscriptionLoading"
          :fields="offerSubscriptionOptionsFields"
          :per-page="numberOfItemsPerPage"
          :current-page="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          hover
          responsive
        >
          <!-- LOADING -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <!-- ROW DETAILS -->
          <!-- specific fields -->
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col cols="12" class="mb-1">
                  <component :is="specifics(row.item)" :items="row.item" :offer-subscription-loading="offerSubscriptionLoading" :offer-edition="offerEdition" />
                </b-col>
              </b-row>
              <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                {{ $t('offer.subscription.hide_specifics') }}
              </b-button>
            </b-card>
          </template>
          <!-- TOP ROW -->
          <!-- mfc licence -->
          <template #top-row>
            <b-th class="top-row-unerline">{{ $t('offer.subscription.licence') }}</b-th>
            <b-td> </b-td>
            <b-td>
              <!-- sellingPriceExcludingTaxes -->
              <validation-provider
                v-if="offerEdition"
                #default="{ errors }"
                :name="$t('offer.subscription.ex_tax_price')"
                vid="offer-subscription-licence-ex-tax-price"
                rules="required|decimalOrInt"
              >
                <b-form-group>
                  <b-input-group :append="currency" class="mt-1 input-group">
                    <b-form-input
                      id="offer-subscription-licence-ex-tax-price"
                      v-model="offerSubscription.edited.sellingPriceExcludingTaxes"
                      :placeholder="$t('offer.subscription.ex_tax_price')"
                      :state="errors[0] && false"
                      type="number"
                      number
                      @input="decreaseLicenseFinalPrice()"
                    />
                  </b-input-group>
                  <b-tooltip v-if="errors[0]" show target="offer-subscription-licence-ex-tax-price" placement="bottom" variant="light">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
              <span v-else>{{ offerSubscription.edited.sellingPriceExcludingTaxes | priceFormat }}</span>
            </b-td>
            <b-td>
              <!-- discountRate -->
              <validation-provider
                v-if="offerEdition"
                #default="{ errors }"
                :name="$t('offer.subscription.discount_rate')"
                vid="offer-subscription-licence-discount-rate"
                rules="percentage"
              >
                <b-form-group>
                  <b-input-group append="%" class="mt-1 input-group">
                    <b-form-input
                      id="offer-subscription-licence-max-subscription"
                      v-model="offerSubscription.edited.discountRate"
                      :placeholder="$t('offer.subscription.discount_rate')"
                      :state="errors[0] && false"
                      type="number"
                      number
                      @input="decreaseLicenseFinalPrice()"
                    />
                  </b-input-group>
                  <b-tooltip v-if="errors[0]" show target="offer-subscription-licence-max-subscription" placement="bottom" variant="light">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
              <span v-else>
                {{ offerSubscription.edited.discountRate }}
                {{ offerSubscription.edited.discountRate && '%' }}
              </span>
            </b-td>
            <b-td>{{ offerSubscription.edited.finalPrice | priceFormat }}</b-td>
          </template>
          <!-- BOTTOM ROW -->
          <!-- total -->
          <template #bottom-row>
            <b-th> {{ $t('offer.subscription.total') }}</b-th>
            <b-td> </b-td>
            <b-td>
              <span class="text-nowrap"> {{ totalSellingPriceExcludingTaxes | priceFormat }}</span>
            </b-td>
            <b-td>
              <span class="text-nowrap"> {{ totalDiscountRate | priceFormat }}</span>
            </b-td>
            <b-td>
              <!-- <b-badge  variant="light-info"> -->
              <span class="text-nowrap">{{ totalFinalPrice | priceFormat }}</span>
              <!-- </b-badge> -->
            </b-td>
          </template>
          <!-- CELL -->
          <!-- specifics -->
          <template #cell(specifics)="row">
            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
              <span>{{ row.detailsShowing ? $t('action.hide') : $t('action.show') }}</span>
            </b-form-checkbox>
          </template>
          <!-- sellingPriceExcludingTaxes -->
          <template #cell(sellingPriceExcludingTaxes)="data">
            <validation-provider
              v-if="offerEdition"
              #default="{ errors }"
              :name="$t('offer.subscription.ex_tax_price')"
              :vid="`offer-subscription-max-ex-tax-price-${data.index}`"
              rules="required|decimalOrInt"
            >
              <b-form-group>
                <b-input-group :append="currency" class="mt-1 input-group">
                  <b-form-input
                    :id="`offer-subscription-max-ex-tax-price-${data.index}`"
                    v-model="offerSubscription.edited.options[data.index].sellingPriceExcludingTaxes"
                    :placeholder="$t('offer.subscription.ex_tax_price')"
                    :state="errors[0] && false"
                    type="number"
                    number
                    @input="price => decreaseOptionFinalPrice(data.index, parseFloat(price), data.item.discountRate)"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" show :target="`offer-subscription-max-ex-tax-price-${data.index}`" placement="bottom" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <span v-else>{{ data.item.sellingPriceExcludingTaxes | priceFormat }}</span>
          </template>
          <!-- discountRate -->
          <template #cell(discountRate)="data">
            <validation-provider
              v-if="offerEdition"
              #default="{ errors }"
              :name="$t('offer.subscription.discount_rate')"
              :vid="`offer-subscription-max-discount-rate-${data.index}`"
              rules="percentage"
            >
              <b-form-group>
                <b-input-group append="%" class="mt-1 input-group">
                  <b-form-input
                    :id="`offer-subscription-max-discount-rate-${data.index}`"
                    v-model="offerSubscription.edited.options[data.index].discountRate"
                    :placeholder="$t('offer.subscription.discount_rate')"
                    :state="errors[0] && false"
                    type="number"
                    number
                    @input="rate => decreaseOptionFinalPrice(data.index, data.item.sellingPriceExcludingTaxes, parseFloat(rate ? rate : 0))"
                  />
                </b-input-group>
                <b-tooltip v-if="errors[0]" show :target="`offer-subscription-max-discount-rate-${data.index}`" placement="bottom" variant="light">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-tooltip>
              </b-form-group>
            </validation-provider>
            <span v-else>{{ data.item.discountRate }} {{ data.item.discountRate && '%' }} </span>
          </template>
          <!-- finalPrice -->
          <template #cell(finalPrice)="data">{{ data.item.finalPrice | priceFormat }}</template>
        </b-table>
        <!--PAGINATION-->
        <div class="mx-2 mb-2">
          <app-data-table-bottom
            table-name="offer-commission-slices-table"
            :page="page"
            :per-page="numberOfItemsPerPage"
            :total-count="totalItems"
            :items-length="numberOfItemsPerPage"
            @pagination="page = $event"
          />
        </div>
        <!-- DESCRIPTIONS -->
        <div class="mt-4">
          <h3 class="mr-2 ml-2 mb-2">
            {{ $tc('offer.subscription.descriptions', 0) }}
          </h3>
          <OfferPartSubscriptionDescriptions
            :offer-subscription-loading="offerSubscriptionLoading"
            :offer-descriptions="offerSubscription.edited.descriptions"
            :offer-edition="offerEdition"
            @updateDescriptions="updateDescriptions($event)"
          />
        </div>

        <!-- submit and reset -->
        <div class="my-2">
          <app-form-multiple-actions
            previous
            :save="offerEdition"
            :save-next="valid && dirty && offerEdition"
            :next="pristine || invalid"
            :disabled="invalid || pristine || offerSubscriptionLoading"
            @click:previous="$router.push(`/offer/${offerId}/commission/${offerEditionMode}`)"
            @click:save="isClickSave()"
            @click:save-next="isClickSaveAndNext()"
            @click:next="$router.push(`/offer/${offerId}/subscribers/${offerEditionMode}`)"
          />
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { sumBy, add, round } from 'lodash'
import { fetchOfferSubscriptionRequest, patchOfferSubscriptionRequest } from '@/request/globalApi/requests/offerRequests'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormMultipleActions from '@/components/AppFormMultipleActions.vue'

import calculation from '@/mixins/calculation'

import OfferPartSubscriptionDescriptions from './OfferPartSubscriptionDescriptions.vue'
import getCurrency from '@/helpers/getCurrency'

export default {
  name: 'OfferPartSubscription',

  components: {
    AppDataTableBottom,
    OfferPartSubscriptionDescriptions,
    AppFormMultipleActions,
    OfferPartSubscriptionOptionsAirport: () => import('./OfferPartSubscriptionOptionsAirport.vue'),
    OfferPartSubscriptionOptionsFuelAirport: () => import('./OfferPartSubscriptionOptionsFuelAirport.vue'),
    OfferPartSubscriptionOptionsHangar: () => import('./OfferPartSubscriptionOptionsHangar.vue'),
    OfferPartSubscriptionOptionsAircaft: () => import('./OfferPartSubscriptionOptionsAircaft.vue'),
  },

  mixins: [calculation],

  props: {
    offerEdition: {
      type: Boolean,
      required: true,
    },
    offerEditionMode: {
      type: String,
      default: 'show',
    },
    offerId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      currency: null,
      offerSubscriptionLoading: false,
      offerSubscription: {
        old: {},
        edited: {},
      },

      numberOfItemsPerPage: 5,
      page: 1,
      sortBy: 'name',
      isSortDirDesc: false,
      clickSaveAndNext: true
    }
  },

  computed: {
    ...mapState('enum', ['paymentFrequency']),
    ...mapState('appConfig', ['lang', 'dataTable']),
    ...mapGetters('offerSubscription', ['offerSubscriptionById']),
    ...mapGetters('offerInformation', ['offerReferenceById']),
    ...mapFields('offerTypology', ['typologiesChanged']),

    offerSubscriptionOptionsFields() {
      return [
        {
          key: 'name',
          label: this.$t('offer.subscription.pricing'),
          sortable: true,
        },
        {
          key: 'specifics',
          label: this.$t('offer.subscription.specifics.title'),
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'sellingPriceExcludingTaxes',
          label: this.$t('offer.subscription.ex_tax_price'),
        },
        {
          key: 'discountRate',
          label: this.$t('offer.subscription.discount_rate'),
        },
        {
          key: 'finalPrice',
          label: this.$t('offer.subscription.final_price'),
          tdClass: 'text-left',
          thClass: 'text-left text-nowrap',
        },
      ]
    },

    totalItems() {
      return this.offerSubscription.edited.options ? this.offerSubscription.edited.options.length : 0
    },

    totalSellingPriceExcludingTaxes() {
      let totalSellingPriceExcludingTaxes = sumBy(this.offerSubscription.edited.options, price => price.sellingPriceExcludingTaxes)
      totalSellingPriceExcludingTaxes = add(totalSellingPriceExcludingTaxes, this.offerSubscription.edited.sellingPriceExcludingTaxes)
      return round(totalSellingPriceExcludingTaxes, 2)
    },

    totalSellingPriceIncludingTaxes() {
      let totalSellingPriceIncludingTaxes = sumBy(this.offerSubscription.edited.options, price => price.sellingPriceIncludingTaxes)
      totalSellingPriceIncludingTaxes = add(totalSellingPriceIncludingTaxes, this.offerSubscription.edited.sellingPriceIncludingTaxes)
      return round(totalSellingPriceIncludingTaxes, 2)
    },

    totalDiscountRate() {
      let totalDiscountRate = sumBy(this.offerSubscription.edited.options, price => price.sellingPriceExcludingTaxes * (price.discountRate / 100))
      totalDiscountRate = add(totalDiscountRate, this.offerSubscription.edited.sellingPriceExcludingTaxes * (this.offerSubscription.edited.discountRate / 100))

      return round(totalDiscountRate, 2)
    },

    totalFinalPrice() {
      let totalFinalPrice = sumBy(this.offerSubscription.edited.options, price => price.finalPrice)
      totalFinalPrice = add(totalFinalPrice, this.offerSubscription.edited.finalPrice)
      return round(totalFinalPrice, 2)
    },
  },

  watch: {
    typologiesChanged: {
      handler(value) {
        if (value) this.fetchOfferSubscription(true)
      },
    },
  },

  mounted() {
    this.currency = getCurrency()
    this.fetchOfferSubscription()
  },

  methods: {
    ...mapActions('offerSubscription', ['setOfferSubscriptionsShowed', 'updateOfferSubscriptionsShowed']),

    resetOfferSubscription() {
      this.offerSubscription.edited = this._cloneDeep(this.offerSubscription.old)
      this.$refs['offer-subscription-observer'].reset()
    },

    saveOfferSubscription(offerSubscription) {
      this.offerSubscription.edited = this._cloneDeep(offerSubscription)
      this.offerSubscription.old = this._cloneDeep(this.offerSubscription.edited)
    },

    submitOfferSubscription(next) {
      this.offerSubscriptionLoading = true
      patchOfferSubscriptionRequest(this.offerId, this.offerSubscription.edited, this.offerReferenceById({ id: this.offerId, lang: this.lang }))
        .then(() => {
          this.fetchOfferSubscription(true)
          this.$emit('offerEdited')
        })
        .then(() => {
          if (next) {
            this.$router.push(`/offer/${this.offerId}/subscribers/${this.offerEditionMode}`)
          }
        })
        .finally(() => {
          this.offerSubscriptionLoading = false
        })
    },

    fetchOfferSubscription(refresh) {
      let offerSubscription = this.offerSubscriptionById(this.offerId)
      if (offerSubscription && !refresh) {
        this.saveOfferSubscription(offerSubscription)
      } else {
        this.offerSubscriptionLoading = true
        fetchOfferSubscriptionRequest(this.offerId)
          .then(response => {
            offerSubscription = response.data
            offerSubscription.options.forEach(option => {
              option.finalPrice = round(this.percentageDecrease(option.sellingPriceExcludingTaxes, option.discountRate), 2)
            })
            offerSubscription.finalPrice = round(this.percentageDecrease(offerSubscription.sellingPriceExcludingTaxes, offerSubscription.discountRate), 2)

            this.saveOfferSubscription(offerSubscription)
            !refresh &&
              this.setOfferSubscriptionsShowed({
                id: this.offerId,
                offerSubscription,
              })
            refresh &&
              this.updateOfferSubscriptionsShowed({
                id: this.offerId,
                offerSubscription,
              })
          })
          .finally(() => {
            this.typologiesChanged = false
            this.offerSubscriptionLoading = false
          })
      }
    },

    updateDescriptions(descriptions) {
      this.offerSubscription.edited.descriptions = descriptions
      this.submitOfferSubscription()
    },

    decreaseLicenseFinalPrice() {
      this.offerSubscription.edited.finalPrice = round(
        this.percentageDecrease(
          this.offerSubscription.edited.sellingPriceExcludingTaxes,
          parseFloat(this.offerSubscription.edited.discountRate ? this.offerSubscription.edited.discountRate : 0)
        ),
        2
      )
    },

    decreaseOptionFinalPrice(index, price, percent) {
      this.offerSubscription.edited.options[index].finalPrice = round(this.percentageDecrease(price, percent), 2)
    },

    specifics(item) {
      if (item.hasOwnProperty('maxAirports')) return 'OfferPartSubscriptionOptionsAirport'
      if (item.hasOwnProperty('maxFuelAirports')) return 'OfferPartSubscriptionOptionsFuelAirport'
      if (item.hasOwnProperty('maxHangars')) return 'OfferPartSubscriptionOptionsHangar'
      if (item.hasOwnProperty('maxAircrafts')) return 'OfferPartSubscriptionOptionsAircaft'
      return ''
    },

    isClickSave() {
      this.clickSaveAndNext = false
    },

    isClickSaveAndNext() {
      this.clickSaveAndNext = true
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.top-row-unerline {
  border-bottom: 2px solid #d6b76d !important;
}
.input-group {
  min-width: 200px;
}
</style>
